import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { Button, colors, Paper, FormGroup, FormControlLabel, FormControl, 
         Switch, Stack, Slider, IconButton, Popover, Typography,
        TextField} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from "react-google-charts";
import './Welcome.css';

import { BarChart } from '@mui/x-charts/BarChart';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import {
    Magnifier,
    GlassMagnifier,
    SideBySideMagnifier,
    PictureInPictureMagnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
  } from "react-image-magnifiers";
  
import usZipCodes from '../../assets/usZipCodes.json'

import AuthInterface from '../AuthInterface/AuthInterface';

import earthImgHD from '../../assets/Welcome/earthImgHD.jpg'
import nashvilleSmall from '../../assets/Welcome/NashvilleLotEagle-small.jpg'   
import nashvilleLarge from '../../assets/Welcome/NashvilleLotEagle-medlarge.png'

// import demo2024 from '../../assets/Welcome/demo/2024-high.jpg'

import timeDemo2022_07 from '../../assets/Welcome/timeSeriesDemo/2022-07.png'
import timeDemo2022_08 from '../../assets/Welcome/timeSeriesDemo/2022-08.png'
import timeDemo2022_09 from '../../assets/Welcome/timeSeriesDemo/2022-09.png'
import timeDemo2022_10 from '../../assets/Welcome/timeSeriesDemo/2022-10.png'
import timeDemo2022_11 from '../../assets/Welcome/timeSeriesDemo/2022-11.png'
import timeDemo2022_12 from '../../assets/Welcome/timeSeriesDemo/2022-12.png'
import timeDemo2023_01 from '../../assets/Welcome/timeSeriesDemo/2023-01.png'
import timeDemo2023_02 from '../../assets/Welcome/timeSeriesDemo/2023-02.png'
import timeDemo2023_03 from '../../assets/Welcome/timeSeriesDemo/2023-03.png'
import timeDemo2023_04 from '../../assets/Welcome/timeSeriesDemo/2023-04.png'
import timeDemo2023_05 from '../../assets/Welcome/timeSeriesDemo/2023-05.png'
import timeDemo2023_06 from '../../assets/Welcome/timeSeriesDemo/2023-06.png'
import timeDemo2023_07 from '../../assets/Welcome/timeSeriesDemo/2023-07.png'
import timeDemo2023_08 from '../../assets/Welcome/timeSeriesDemo/2023-08.png'
import timeDemo2023_09 from '../../assets/Welcome/timeSeriesDemo/2023-09.png'
import timeDemo2023_10 from '../../assets/Welcome/timeSeriesDemo/2023-10.png'
import timeDemo2023_11 from '../../assets/Welcome/timeSeriesDemo/2023-11.png'
import timeDemo2023_12 from '../../assets/Welcome/timeSeriesDemo/2023-12.png'
import timeDemo2024_01 from '../../assets/Welcome/timeSeriesDemo/2024-01.png'
import timeDemo2024_02 from '../../assets/Welcome/timeSeriesDemo/2024-02.png'
import timeDemo2024_03 from '../../assets/Welcome/timeSeriesDemo/2024-03.png'
import timeDemo2024_04 from '../../assets/Welcome/timeSeriesDemo/2024-04.png'
import timeDemo2024_05 from '../../assets/Welcome/timeSeriesDemo/2024-05.png'
import timeDemo2024_06 from '../../assets/Welcome/timeSeriesDemo/2024-06.png'

import { returnZipCodeLatLng } from '../../functions/zipCodes';


import { AddCircle, ArrowCircleDown, ArrowCircleUp, Biotech, Gavel, HolidayVillage, Info, Search, LockOpen, Cloud, Forum, Psychology, AutoFixHigh, ZoomIn, ZoomInMap, ViewInAr, Crop, BurstMode, RemoveRedEye, DateRange, Person  } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';

function Welcome(props) {

    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const theme = useTheme()
    const navigate = useNavigate()
    const timeSeriesDemoInterval = 500

    const numberOfSections = 4
    

    const backgroundImgRef = useRef(null)
    const scrollContainerRef = useRef(null)
    const [sectionSelectorMarginTop, setSectionSelectorMarginTop] = useState(0)

    const viewportWidthPxRef = useRef(window.innerWidth)
    const [viewportWidthPx, setViewportWidthPx] = useState(viewportWidthPxRef.current)
    const viewportRatioRef = useRef(window.innerWidth / window.innerHeight)
    const isMobileRef = useRef(viewportRatioRef.current < .75)
    const [isMobile, setIsMobile] = useState(isMobileRef.current)


    const [highAltitudeImgPercent, setHighAltitudeImgPercent] = useState(100)
    const [mediumAltitudeImgPercent, setMediumAltitudeImgPercent] = useState(100)
    const [lowAltitudeImgPercent, setLowAltitudeImgPercent] = useState(100)



    const reasonItsCheapUpdateTime = useRef(0)
    const reasonItsCheapIndexRef = useRef(0)
    const reasonItsCheapTextRef = useRef(null)
    
    const [reasonsItsCheapIndex, setReasonsItsCheapIndex] = useState(0)
    const [reasonsItsCheapNextIndex, setReasonsItsCheapNextIndex] = useState(0)
    
    const [reasonButtonWidth, setReasonButtonWidth] = useState('auto')
    const [reasonButtonTextOpacity, setReasonButtonTextOpacity] = useState(1)

    const currentSectionRef = useRef(0)
    const [currentSection, setCurrentSection] = useState(0)

    const [imgZoomFactor, setImgZoomFactor] = useState(1)

    const [priceCompareInfoPopoverAnchorEl, setPriceCompareInfoPopoverAnchorEl] = useState(null)

    const [imageDemoimageBrightness, setImageDemoImageBrightness] = useState(.7)
    const [imageDemoCoverOpacity, setImageDemoCoverOpacity] = useState(1)
    const [imageDemoCoverActive, setImageDemoCoverActive] = useState(true)

    const lastScrollTimeRef = useRef(0)
    const lastScrollPositionRef = useRef(0)

    const timeSeriesDemoPausedRef = useRef(false)
    const timeSeriesDemoIndexRef = useRef(0)
    const [timeSeriesDemoIndex, setTimeSeriesDemoIndex] = useState(0)

    const [zipCode, setZipCode] = useState('')

    const reasonsItsCheap = [
        {
            text: "We don't have a huge sales team based in San Francisco.",
            icon: <HolidayVillage style={{fontSize: '35px'}} />,
        },
        {
            text: "Most people don't need 30cm resolution.",
            icon: <Biotech style={{fontSize: '35px'}} />,
        },
        {
            text: "We use the cloud to store and process images.",  
            icon: <Cloud style={{fontSize: '35px'}} />,
        },
        {
            text: "We don't need a massive marketing budget, word of mouth does wonders.",
            icon: <Forum style={{fontSize: '35px'}} />,
        },
        {
            text: "We didn't hire a focus group to name our company.",
            icon: <Psychology style={{fontSize: '35px'}} />,
        },
        {   
            text: "We use AI to help automate our data processing.",
            icon: <AutoFixHigh style={{fontSize: '35px'}} />,
        },
        {   
            text: "We use open-source whenever possible—because it's free.",
            icon: <LockOpen style={{fontSize: '35px'}} />,
        },
        
    ]

    const [priceComparisonDataState, setPriceComparisonDataState ] = useState({
        Airbus: {
            company: 'Airbus',
            satellite: 'Pléiades',
            price: 20,
            label: '$20',
            resolution: '0.3m',
            visible: true,
        },
        Planet: {
            company: 'Planet',
            satellite: 'PlanetScope',
            price: 2.5,
            label: '$2.50',
            resolution: '4.7m',
            visible: true,
        },
        LotEagle: {
            company: 'Lot Eagle',
            satellite: 'Sentinel-2',
            price: 0.2,
            label: '$0.20',
            resolution: '10m',
            visible: true,
        },
    })

    const timeSeriesDemoImages = [
        {
            src: timeDemo2022_07,
            value: 0,
            label: '',
            date: 'Sep 2022',
        },
        {
            src: timeDemo2022_08,
            value: 1,
            label: '',
            date: 'Oct 2022',
        },
        {
            src: timeDemo2022_09,
            value: 2,
            label: '',
            date: 'Nov 2022',
        },
        {
            src: timeDemo2022_10,
            value: 3,
            label: '',   
            date: 'Dec 2022',
        },
        {
            src: timeDemo2022_11,
            value: 4,
            label: '',
            date: 'Jan 2023',
        },
        {
            src: timeDemo2022_12,
            value: 5,
            label: '',
            date: 'Feb 2023',
        },
        {
            src: timeDemo2023_01,
            value: 6,
            label: '',
            date: 'Mar 2023',
        },
        {
            src: timeDemo2023_02,
            value: 7,
            label: '',
            date: 'Apr 2023',
        },
        {
            src: timeDemo2023_03,
            value: 8,
            label: '',
            date: 'May 2023',
        },
        {
            src: timeDemo2023_04,
            value: 9,
            label: '',
            date: 'Jun 2023',
        },
        {
            src: timeDemo2023_05,
            value: 10,
            label: '',
            date: 'Jul 2023',
        },
        {
            src: timeDemo2023_06,
            value: 11,
            label: '',
            date: 'Aug 2023',
        },
        {
            src: timeDemo2023_07,
            value: 12,
            label: '',
            date: 'Sep 2023',
        },
        {
            src: timeDemo2023_08,
            value: 13,
            label: '',
            date: 'Oct 2023',
        },
        {
            src: timeDemo2023_09,
            value: 14,
            label: '',
            date: 'Nov 2023',
        },
        {
            src: timeDemo2023_10,
            value: 15,
            label: '',
            date: 'Dec 2023',
        },
        {
            src: timeDemo2023_11,
            value: 16,
            label: '',
            date: 'Jan 2024',
        },
        {
            src: timeDemo2023_12,
            value: 17,
            label: '',
            date: 'Feb 2024',
        },
        {
            src: timeDemo2024_01,
            value: 18,
            label: '',
            date: 'Mar 2024',
        },
        {
            src: timeDemo2024_02,
            value: 19,
            label: '',
            date: 'Apr 2024',            
        },
        {
            src: timeDemo2024_03,
            value: 20,
            label: '',
            date: 'May 2024',
        },
        {
            src: timeDemo2024_04,
            value: 21,
            label: '',
            date: 'Jun 2024',
        }, 
        {
            src: timeDemo2024_05,
            value: 22,
            label: '',
            date: 'Jul 2024',
        },
        {
            src: timeDemo2024_06,
            value: 23,
            label: '',
            date: 'Aug 2024',
        },        

    ]


    useEffect(() => {

        const interval = setInterval(() => {
            if (timeSeriesDemoPausedRef.current == false) {
                if(timeSeriesDemoIndexRef.current < 23) {
                    timeSeriesDemoIndexRef.current = timeSeriesDemoIndexRef.current + 1                    
                }else{
                    timeSeriesDemoIndexRef.current = 0               
                }
                setTimeSeriesDemoIndex(timeSeriesDemoIndexRef.current)
            }
        }, timeSeriesDemoInterval)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                //get aspect ratio
                const vw = window.innerWidth
                const vh = window.innerHeight
                const ratio = vw/vh
                viewportWidthPxRef.current = ratio
                isMobileRef.current = ratio < .75
                setIsMobile(isMobileRef.current)

                changeSections(currentSectionRef.current, true)
                updateBackroundImage()
                
            }, 500);
        })
    }, [])


    
    useEffect(() => {
        const div = scrollContainerRef.current;

        function handleScroll(event) {
            event.preventDefault(); // Prevent the default scroll behavior

            if(event.type == "wheel"){
                //check if the user is scrolling down or up
                if (event.deltaY > 0) {
                    //scrolling down
                    changeSections(currentSectionRef.current + 1, false)
                }else{
                    //scrolling up
                    changeSections(currentSectionRef.current - 1, false)
                }
            }        
        }

        // Add the event listener with passive: false
        div.addEventListener('wheel', handleScroll, { passive: false });
        div.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            div.removeEventListener('wheel', handleScroll);
            div.removeEventListener('scroll', handleScroll);
        };

    }, [])

    useEffect(() => {

        timeSeriesDemoIndexRef.current = -2
        setTimeSeriesDemoIndex(timeSeriesDemoIndexRef.current)


    }, [currentSection])

    function updateBackroundImage() {
        try{

            const vw = window.innerWidth
            const vh = window.innerHeight

            viewportRatioRef.current = vw / vh
            isMobileRef.current = viewportRatioRef.current < .75

            const percentScrolled = currentSectionRef.current / numberOfSections <= 1 ? currentSectionRef.current / numberOfSections : 1

            console.log(percentScrolled)
            console.log(1 - (Math.sqrt((percentScrolled - .6 < 0 ? 0:percentScrolled - .6 ) * .75 )))

            
            const totalPixels = isMobileRef.current ? (-vw*1.77/viewportRatioRef.current)+vh: (-vw*1.77)+vh
            backgroundImgRef.current.style.transform = `translateY(${totalPixels * percentScrolled}px)`; // Adjust the multiplier for slower or faster parallax
            backgroundImgRef.current.style.opacity = 1 - (Math.sqrt((percentScrolled - .6 < 0 ? 0:percentScrolled - .6 ) * .75 )); // Adjust the multiplier for slower or faster parallax            
        }catch(e) {
            console.error(e)
        }
    }

    const companyFormatter = (value, context) => { 
        try{
            const company = Object.values(priceComparisonDataState).find((item) => item.company == value)

            if(isMobileRef.current) {
                return context.location === 'tick' ? `${value}`: `${value} | ${company.satellite} ${company.resolution}` 
            }else{
                return context.location === 'tick' ? `${value} (${company.resolution})`: `${value} | ${company.satellite}` 

            }
        } catch (e) {
            console.error(e)
            return value
        }
    }
    const priceFormatter = (value, context) => { return `$${Math.floor(value)}` } 
    const barChartValueFormatter = (value) => { return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })).format(value) } 


    function startDemo(_start){
        if(_start) {
            setImageDemoCoverOpacity(0)
            setTimeout(() => {
                setImageDemoCoverActive(false)
                setImageDemoImageBrightness(1.1)

            }, 550)
        }else{
            setImageDemoImageBrightness(.7)
            setTimeout(() => { 

                setImageDemoCoverOpacity(1)
                setImageDemoCoverActive(true)  

            }, 550);
        }
            
    }

    function changeSections(_section, _immediate) {

        if(_section < 0){
            _section = 0
        }else if(_section > numberOfSections){
            _section = numberOfSections
        }

        const sectionHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 60;
        //prevents multiple scrolls from firing

        if(lastScrollTimeRef.current + 1000 < (new Date()).getTime() || _immediate) {

            currentSectionRef.current = _section
            try{
                setSectionSelectorMarginTop(`calc(-${100 * _section}vh + ${60 * _section}px)`)
    
                lastScrollTimeRef.current = (new Date()).getTime()    
            }catch(e){
                console.error(e)
            }

            updateBackroundImage()

            // const percentScrolled = scrollTop / totalHeight <= 1 ? scrollTop / totalHeight : 1  
            // const totalPixels = isMobileRef.current ? (-vw*1.77/viewportRatioRef.current)+vh: (-vw*1.77)+vh

            // //calculate which section we are in 0 through numberOfSections
            // const sectionHeight = vh
            // const section = Math.floor(scrollTop/sectionHeight)
            // if (section != currentSectionRef.current) {
            //     currentSectionRef.current = section
            //     setCurrentSection(section)
            // }

            // backgroundImgRef.current.style.transform = `translateY(${totalPixels * percentScrolled}px)`; // Adjust the multiplier for slower or faster parallax


            // backgroundImgRef.current.style.opacity = 1 - (Math.sqrt((percentScrolled - .6) * 1 )); // Adjust the multiplier for slower or faster parallax            
        }

    }  

    return (
        <div className="welcomeContent" >
            <img ref={backgroundImgRef} src={earthImgHD} alt="earth" className="backgroundImg" style={{width: `${viewportWidthPx}px`, height: isMobile? `${viewportWidthPx*1.77/viewportRatioRef.current}px`:`${viewportWidthPx*1.77}px`}} />            
            <div ref={scrollContainerRef} className='welcomeContentScroller'>
                <div className='contentHolder' style={{marginTop: sectionSelectorMarginTop}}>
                    <div className='section01'>                    
                        <div>
                            <h1>Satellite imagery for 92% less</h1>
                            
                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{
                                    textTransform: 'none',
                                    marginBottom: '20px',
                                    fontSize: '1.5em',
                                    width: '100%',
                                }}
                                size={'large'}
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(1, true)}}>
                                Next
                            </Button>  
                        </div>
                    </div>

                    <div className='section03'>
                        <h1>Why pay for <br />ultra-high resolution <br />you don't need?</h1>
                        
                        <div className='navigationButtonHolder'>
                            <IconButton 
                                variant='contained'
                                color='primary'
                                style={{                                
                                    marginRight: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                                size='large'
                                onClick={() => {
                                    changeSections(0, true)
                                }}>
                                <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText }}/>
                            </IconButton>                                 
                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{                                
                                    textTransform: 'none',
                                    fontSize: '1.5em',
                                    width: '100%',
                                    flex: 1,
                                }}
                                size='large'
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(2, true)}}>
                                Next
                            </Button>                       
                        </div>
                        
                    </div>
                    <div className='section04'>
                        <h1>How do our <br /> prices compare?</h1>
                        <div className='priceCompareHolder'>
                            <Paper className='barChart' elevation={10} style={{borderColor: theme.palette.primary.main, flexDirection: 'column'}}>                           
                                <h3>
                                    Price USD per km<sup>2</sup>
                                    <IconButton
                                        onClick={(event) => setPriceCompareInfoPopoverAnchorEl(event.currentTarget)}
                                        size='small'
                                        style={{marginLeft: '10px'}}
                                        aria-describedby={Boolean(priceCompareInfoPopoverAnchorEl) ? 'priceCompareInfoPopoverButton':undefined}
                                        >
                                            <Info />
                                    </IconButton>
                                    <Popover
                                        id={Boolean(priceCompareInfoPopoverAnchorEl) ? 'priceCompareInfoPopoverButton':undefined}
                                        open={Boolean(priceCompareInfoPopoverAnchorEl)}
                                        anchorEl={priceCompareInfoPopoverAnchorEl}
                                        onClose={() => setPriceCompareInfoPopoverAnchorEl(null)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        >
                                        <Typography sx={{ p: 2 }}>Comparison based on <a href='https://skywatch.com/data-pricing/' target='_blank' style={{color: theme.palette.primary.main, fontWeight: 'bold', textDecoration: 'underline'}}>SkyWatch 2024</a> pricing data.</Typography>
                                    </Popover>

                                </h3>
                                <BarChart
                                    dataset={Object.values(priceComparisonDataState).filter((item) => item.visible)}
                                    xAxis={[{ scaleType: 'band', dataKey: 'company', valueFormatter: companyFormatter }]}
                                    yAxis={[{ 
                                        scaleType: 'sqrt', 
                                        dataKey: 'price', 
                                        max: Math.max(...Object.values(priceComparisonDataState).filter((item) => item.visible).map((item) => item.price)) + 1, 
                                        valueFormatter: priceFormatter,                                    

                                    }]}
                                    barLabel={(item, context) => barChartValueFormatter(item.value)}    
                                    borderRadius={5}
                                    series={[{ 
                                        dataKey: 'price', 
                                        valueFormatter: barChartValueFormatter,
                                    }]}     
                                    
                                    colors={[theme.palette.secondary.main]}/>
                                    
                            </Paper>
                            <Paper 
                                className='priceCompareLegendHolder'
                                elevation={10}
                                >
                                    <div className='priceCompareLegend'>
                                        <h3>Data Providers</h3>
                                        <FormGroup>
                                            {
                                                Object.keys(priceComparisonDataState).map((key, index) => {

                                                    const item = priceComparisonDataState[key]
                                                    return (
                                                        <FormControlLabel 
                                                            key={index}
                                                            control={
                                                                <Switch
                                                                    checked={item.visible} 
                                                                    onChange={(event) => {
                                                                        const temp = {...priceComparisonDataState}
                                                                        temp[key].visible = event.target.checked
                                                                        setPriceComparisonDataState(temp)
                                                                    }}
                                                                    color='secondary'
                                                                    defaultChecked />
                                                                } 
                                                            label={companyFormatter(item.company, {location: 'tick'})}                                                         
                                                            />
                                                    )
                                                })
                                            }                                                                                
                                        </FormGroup>
                                    </div>
                            </Paper>
                        </div>
                        <div className='navigationButtonHolder'>
                        <IconButton 
                                variant='contained'
                                color='primary'
                                style={{                                
                                    marginRight: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                                size='large'
                                onClick={() => {
                                    changeSections(1, true)
                                }}>
                                <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText}}/>
                            </IconButton>                                 
                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{                                
                                    textTransform: 'none',
                                    fontSize: '1.5em',
                                    width: '100%',
                                    flex: 1,
                                }}
                                size='large'
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(3, true)}}>
                                Next
                            </Button>          
                        </div>
                    </div>

                    <div className='section06'>
                        <h1>Track changes <br /> as they happen</h1>
                        <Paper
                            className='changeDetectionHolder'
                            elevation={10}
                            >
                            <div 
                                className='changeDetectionImageHolder' 
                                onClick={() => {
                                    timeSeriesDemoPausedRef.current = !timeSeriesDemoPausedRef.current
                                }}
                                style={{cursor: 'pointer'}}>
                                <h3>{timeSeriesDemoImages[timeSeriesDemoIndex >= 0 ? timeSeriesDemoIndex:0].date}</h3>
                                {
                                    timeSeriesDemoImages.map((item, index) => {

                                        return <img 
                                                    key={`changeDetection-${index}`} 
                                                    src={item.src} 
                                                    style={{
                                                        opacity: (timeSeriesDemoIndex >= 0 ? timeSeriesDemoIndex:0) >= item.value ? 1 : 0,
                                                    }} 
                                                    className="changeDetectionImage" />
                                    })
                                }
                            </div>
                            <Paper
                                className='changeDetectionSliderHolder'
                                elevation={10}>
                                <Slider 
                                    size="small" 
                                    aria-label="Month Picker" 
                                    color="secondary" 
                                    value={timeSeriesDemoIndex} 
                                    min={0}
                                    step={1}
                                    max={23}
                                    marks={timeSeriesDemoImages.map((item) => { return {value: item.value, label: item.label}})}
                                    onChange={(event, number) => { 
                                        timeSeriesDemoPausedRef.current = true
                                        timeSeriesDemoIndexRef.current = number
                                        setTimeSeriesDemoIndex(timeSeriesDemoIndexRef.current)
                                    }}
                                    style={{margin: 0}}/>

                            </Paper>
                        </Paper>
                        <div className='navigationButtonHolder'>
                            <IconButton 
                                variant='contained'
                                color='primary'
                                style={{                                
                                    marginRight: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                }}
                                size='large'
                                onClick={() => {
                                    changeSections(2, true)
                                }}>
                                <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText}}/>
                            </IconButton>                                 
                            <Button 
                                variant='contained'
                                color='secondary'
                                style={{                                
                                    textTransform: 'none',
                                    fontSize: '1.5em',
                                    width: '100%',
                                    flex: 1,
                                }}
                                size='large'
                                startIcon={<ArrowCircleDown style={{fontSize: '30px', marginRight: '5px'}}/>}
                                onClick={() => {changeSections(4, true)}}>
                                Next
                            </Button>    
                        </div>                        
                    </div>
                    
                    <div className='section08'>
                        <h1>Ready to get started?</h1>                        
                        <div className='section08ButtonHolder'>
                            <Paper className='section08ButtonZipHolder'>                               
                                <TextField 
                                    id="filled-basic" 
                                    label="Zip / Postal Code" 
                                    autoComplete="billing postal-code"
                                    variant="outlined" 
                                    className='section08ButtonTextInput'
                                    value={zipCode}
                                    onChange={(e) => setZipCode(e.target.value)}/>
                                <Button 
                                    variant='contained'
                                    color="secondary"
                                    size='large'
                                    startIcon={<AddCircle />}
                                    onClick={() => {
                                        navigate('/Welcome/CreateLot/' + zipCode)
                                    }}
                                    disabled={returnZipCodeLatLng(zipCode) == null}
                                    className='section08SubmitButton'>
                                        Submit
                                </Button>                         
                            </Paper>
                            <Button 
                                variant='text'
                                color="white"
                                size='large'
                                onClick={() => {
                                    navigate('/Welcome/CreateLot')
                                }}
                                style={{
                                    marginTop: '10px'
                                }}>
                                    Continue without a zip / postal code
                            </Button>
                            <div className='navigationButtonHolder'>
                                <IconButton 
                                    variant='contained'
                                    color='primary'
                                    style={{                                
                                        marginRight: '10px',
                                        backgroundColor: theme.palette.primary.main,
                                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                                    }}
                                    size='large'
                                    onClick={() => {
                                        changeSections(3, true)
                                    }}>
                                        <ArrowCircleUp style={{fontSize: '30px', color: theme.palette.primary.contrastText}}/>
                                </IconButton>                                
                                <div style={{flex: 1}}/>   
                            </div>     
                        </div>
                        
                    </div> 
                </div>
            </div>            
        </div>
    )

}


export default Welcome