import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './AdminDashboard.css'

import Footer from '../Footer/Footer'
import { fetchFromAPI } from '../../functions/fetchFromAPI';
import { Badge, Button, Chip, Paper, Skeleton, Tabs, Tab, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,  } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import { invoiceStatuses } from '../StripeStatuses/StripeStatuses';

import { Dashboard, Public } from '@mui/icons-material';

function AdminDashboard(props) {

    const firestore = firebase.firestore();

    const actionTimeout = 5000

    const adminMode = props.adminMode
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const adminDashboardObj = props?.adminDashboardObj
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()

    const [rerenderState, setRerenderState] = useState(0)

    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedTabCloudOperations, setSelectedTabCloudOperations] = useState(0)

    const [earthEngineActiveTasks, setEarthEngineActiveTasks] = useState(null)
    const [fetchingEarthEngineActiveTasks, setFetchingEarthEngineActiveTasks] = useState(false)

    const analyticRequestsRef = useRef({})
    const imageryRequestsRef = useRef({})
    const [analyticRequests, setAnalyticRequests] = useState({})
    const [imageryRequests, setImageryRequests] = useState({})



    const actionTimeoutsRef = useRef({})
    const [actionTimeouts, setActionTimeouts] = useState({})
    

    const processingDateOptions = { 
        year: 'numeric', 
        month: 'numeric', 
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    }

    useEffect(() => {

        listenForLotOperations()
        
    }, [])

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
    
    function getEarthEngineActiveTasks() {
        setFetchingEarthEngineActiveTasks(true)

        //fetchFromApi
        fetchFromAPI('loteagle', 'get_ee_active_tasks', {method: 'GET'})
        .then((response) => {
            const activeTasks = response.activeTasks ?? []
            setEarthEngineActiveTasks(activeTasks)
            setFetchingEarthEngineActiveTasks(false)
        })
        .catch((error) => {
            console.error(error)
            createAlert('error', error.message)
            setEarthEngineActiveTasks([])
        })
        .finally(() => {
            setFetchingEarthEngineActiveTasks(false)
        })



    }

    function changeTabs(newValue) {
        setSelectedTab(newValue)
    }

    function changeTabsCloudOperations(newValue) {
        setSelectedTabCloudOperations(newValue)
    
    }

    function submitLotAction(_requestId, _actionType){

        if(_actionType == "analyticRequest"){
            
            const addObj = {
                action: 'process',
                createdBy: auth.currentUser.uid,
                dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
                analyticRequestId: _requestId
            }

            firestore.collection('Operations').doc('LotActions').collection('AnalyticRequestActions').add(addObj)
            .then((docRef) => {
                createAlert('success', `Analytic request ${_requestId} submitted`)
            })
            .catch((error) => {
                createAlert('error', error.message)
            })
            
        }else if(_actionType == "imageryRequest"){
            
            const addObj = {
                action: 'process',
                createdBy: auth.currentUser.uid,
                dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
                imageryRequestId: _requestId
            }

            firestore.collection('Operations').doc('LotActions').collection('ImageryRequestActions').add(addObj)
            .then((docRef) => {
                createAlert('success', `Imagery request ${_requestId} submitted`)
            })
            .catch((error) => {
                createAlert('error', error.message)
            })

        }else{
            createAlert('error', 'Unknown action type')

        }        

        actionTimeoutsRef.current[`${_actionType}-${_requestId}`] = (new Date()).getTime() + actionTimeout
        setActionTimeouts(actionTimeoutsRef.current)

    }

    function listenForLotOperations(){
        
        //query Operations/LotOperations/AnalyticRequests where processingComplete != true
        //listen for changes

        const analyticRequestQuery = firestore.collection('Operations')
                                                .doc('LotOperations')
                                                .collection('AnalyticRequests')
                                                .where('processingComplete', '==', false)
                                                .where('startProcessingDate', '<=', new Date())

        const imageryRequestQuery = firestore.collection('Operations')
                                                .doc('LotOperations')
                                                .collection('ImageryRequests')
                                                .where('processingComplete', '==', false)
                                                .where('startProcessingDate', '<=', new Date())

        analyticRequestQuery.onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {

                //if the snapshot no longer exists, remove it from the state
                if(change.type === 'removed'){
                    delete analyticRequestsRef.current[change.doc.id]
                }else if(change.type === 'modified'){
                    analyticRequestsRef.current[change.doc.id] = change.doc.data()
                }else if(change.type === 'added'){
                    analyticRequestsRef.current[change.doc.id] = change.doc.data()
                }else{
                    console.error('Unknown change type', change)
                }
                setAnalyticRequests(analyticRequestsRef.current)
                setRerenderState(Math.random() * 10000)
            });

            
        });

        imageryRequestQuery.onSnapshot((snapshot) => {
            console.log("imageryRequestQuery snapshot", snapshot)
            snapshot.docChanges().forEach((change) => {
                console.log("imageryRequestQuery change", change)
                if(change.type === 'removed'){
                    delete imageryRequestsRef.current[change.doc.id]
                }else if(change.type === 'modified'){
                    imageryRequestsRef.current[change.doc.id] = change.doc.data()
                }else if(change.type === 'added'){
                    imageryRequestsRef.current[change.doc.id] = change.doc.data()
                }else{
                    console.error('Unknown change type', change)
                }

                setImageryRequests(imageryRequestsRef.current)
                setRerenderState(Math.random() * 10000)
            });

            
        });

    }

    const numberOfImageryRequests = Object.keys(imageryRequests).length
    const numberOfAnalyticRequests = Object.keys(analyticRequests).length


    return (
        <div className="adminDashboardContent">
            <div className='adminDashboardContentScroller'>
                <Paper elevation={2} className='adminDashboardContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <Dashboard style={{marginRight: '10px'}}/>
                        Admin Dashboard
                    </div>
                    
                    <Tabs 
                        value={selectedTab} 
                        onChange={(event, newValue) => changeTabs(newValue)} 
                        aria-label="basic tabs example"
                        style={{
                            display: 'flex',
                        }}>                        
                            <Tab 
                                label={
                                    numberOfAnalyticRequests + numberOfImageryRequests > 0 ?
                                            <Badge badgeContent={numberOfAnalyticRequests + numberOfImageryRequests} color="primary">
                                                <span style={{paddingRight: '15px'}}>Cloud Operations</span>
                                            </Badge>
                                            :"Cloud Operations"
                                } 
                                // icon={<CropFree />} 
                                iconPosition="start"  
                                {...a11yProps(0)} />   
                            <Tab 
                                label={"Earth Engine"} 
                                // icon={<CropFree />} 
                                iconPosition="start"  
                                {...a11yProps(1)} />                                                                                        
                    </Tabs>                                
                    <Divider 
                        style={{
                            display: 'flex',
                        }}/>
                    <div className='adminDashboardContentArea' style={{display: selectedTab == 0 ? "block":"none"}}>
                        <Tabs 
                            value={selectedTabCloudOperations} 
                            onChange={(event, newValue) => changeTabsCloudOperations(newValue)} 
                            aria-label="basic tabs example"
                            indicatorColor='primary'
                            style={{
                                display: 'flex',
                                backgroundColor: '#f9a825',
                            }}>                        
                                <Tab 
                                    label={
                                        numberOfAnalyticRequests > 0 ?
                                            <Badge badgeContent={numberOfAnalyticRequests} color="primary">
                                                <span style={{paddingRight: '15px'}}>Analytics</span>
                                            </Badge>
                                            :"Analytics"
                                    } 
                                    // icon={<CropFree />} 
                                    iconPosition="start"  
                                    {...a11yProps(0)} />   
                                <Tab 
                                    label={
                                        numberOfImageryRequests > 0 ?
                                            <Badge badgeContent={numberOfImageryRequests} color="primary">
                                                <span style={{paddingRight: '15px'}}>Imagery</span>
                                            </Badge>
                                            :"Imagery"
                                    } 
                                    // icon={<CropFree />} 
                                    iconPosition="start"  
                                    {...a11yProps(1)} />                                                                                        
                        </Tabs> 
                        <Divider 
                            style={{
                                display: 'flex',
                            }}/>
                        <div className='adminDashboardContentArea' style={{display: selectedTabCloudOperations == 0 ? "block":"none"}}>
                            <TableContainer component={Paper} elevation={3} style={{  width: 'calc(100% - 0px)', maxHeight: 'calc(100vh - 500px)', display: true ? 'block':'none'}} >
                                <Table aria-label="" stickyHeader render={rerenderState}>
                                    <TableHead >
                                        <TableRow style={{minHeight: '60px !important'}}>
                                            <TableCell>Lot</TableCell>
                                            <TableCell>Invoice</TableCell>                                            
                                            <TableCell>Square Status</TableCell>                                                
                                            <TableCell>Updated</TableCell>      
                                            <TableCell>Action</TableCell>                                            
                                        </TableRow>
                                        </TableHead>
                                    <TableBody>
                                    {
                                    Object.keys(analyticRequests).map((key) => {
                                        const task = imageryRequests[key]
                                        return (
                                            <TableRow
                                                key={`analyticRequest-${key}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, minHeight: '60px !important' }}>
                                                <TableCell style={{display: 'flex', flexDirection: 'column'}}>
                                                    <span>{task.displayName}</span> 
                                                    <span>{task.dateRangeId}</span>
                                                </TableCell>
                                                <TableCell>
                                                    {invoiceStatuses[task?.invoiceStatus] ?? task?.invoiceStatus}
                                                </TableCell>
                                                <TableCell>
                                                   
                                                </TableCell>
                                                <TableCell>
                                                   {(new Date(task?.dateUpdated?.seconds * 1000)).toLocaleDateString("en-US", processingDateOptions)}
                                                </TableCell>
                                                <TableCell>
                                                   
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                        <div className='adminDashboardContentArea' style={{display: selectedTabCloudOperations == 1 ? "block":"none"}}>
                        <TableContainer component={Paper} elevation={3} style={{  width: 'calc(100% - 0px)', maxHeight: 'calc(100vh - 500px)', display: true ? 'block':'none'}} >
                                <Table aria-label="" stickyHeader >
                                    <TableHead >
                                        <TableRow style={{minHeight: '60px !important'}}>
                                            <TableCell>Lot</TableCell>
                                            <TableCell>Invoice</TableCell>                                            
                                            <TableCell>Square Status</TableCell>                                                

                                            <TableCell>Updated</TableCell>      
                                            <TableCell>Action</TableCell>                                            
                                        </TableRow>
                                        </TableHead>
                                    <TableBody>
                                    {
                                    Object.keys(imageryRequests).map((key) => {
                                        const task = imageryRequests[key]
                                        console.log(key, task)
                                        return (
                                            <TableRow
                                                key={`analyticRequest-${key}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, minHeight: '60px !important' }}>
                                                <TableCell style={{display: 'flex', flexDirection: 'column'}}>
                                                    <span>{task.displayName}</span> 
                                                    <span>{task.dateRangeId}</span>
                                                </TableCell>
                                                <TableCell>
                                                    {invoiceStatuses[task?.invoiceStatus] ?? task?.invoiceStatus}
                                                </TableCell>
                                                <TableCell>
                                                   {Object.values(task?.processingStatus?.squares ?? {}).map((square) => { return square.complete == true }).filter((value) => { return value == true }).length} / { (task?.requiredSquares ?? []).length}
                                                </TableCell>
                                                <TableCell>
                                                   {(new Date(task?.dateUpdated?.seconds * 1000)).toLocaleDateString("en-US", processingDateOptions)}
                                                </TableCell>
                                                <TableCell>
                                                    <Button 
                                                        variant="contained"
                                                        color="primary"
                                                        size="small"
                                                        disabled={actionTimeouts[`${'imageryRequest'}-${key}`] > (new Date()).getTime()}
                                                        onClick={() => {
                                                            submitLotAction(key, 'imageryRequest')
                                                        }}>
                                                        Re-Process             
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    </div>
                    <div className='adminDashboardContentArea' style={{display: selectedTab == 1 ? "block":"none"}}>
                        <h2>Earth Engine Active Tasks</h2>
                        <div className='earthEngineActiveTasks'>
                            {
                                fetchingEarthEngineActiveTasks ? 
                                <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                                :earthEngineActiveTasks?.map((task, index) => {
                                    return (
                                        <Chip
                                            key={task}
                                            label={task}
                                            color='secondary'
                                            style={{margin: '5px'}}/>
                                    )
                                })
                            }                            
                            {
                                earthEngineActiveTasks?.length === 0 && !fetchingEarthEngineActiveTasks ? <h3 style={{margin: '0px'}}>No active tasks</h3> : null
                            }
                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<Public />}
                            disabled={fetchingEarthEngineActiveTasks}   
                            onClick={() => {
                                getEarthEngineActiveTasks()
                            }}>
                                Get Active Tasks
                        </Button>
                    </div>

                </Paper>
                
            </div>            
            <Footer></Footer>
            
        </div>
    )

}


export default AdminDashboard