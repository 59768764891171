

export function calculateRelativeDateSliderMarkValue(_marks, _analyticKey, _relativeValue){
    try{

      var analyticDateTime 
      if(_analyticKey == null){
        analyticDateTime = _marks[_marks.length - 1].value
      }else{
        analyticDateTime= (new Date(Date.UTC(parseInt(_analyticKey.split('-')?.[0]), parseInt(_analyticKey.split('-')?.[1]) - 1, parseInt(_analyticKey.split('-')?.[2])))).getTime()      

      }
      const selectedMark = _marks?.find(mark => mark.value == analyticDateTime)

      if(selectedMark == null){
        return _marks[_marks.length - 1].value
      }
      
      const currentIndex = _marks.indexOf(selectedMark)

      const newIndex = currentIndex + _relativeValue

      if(newIndex < 0){
        return _marks[0].value
      }else if(newIndex >= _marks.length){
        return _marks[_marks.length - 1].value
      }else{

        return _marks[newIndex].value
      }
    }catch(error){
      console.error("Error calculating relative date slider mark value: " + error)
      return 0
    }
  }